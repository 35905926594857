import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import BaseFunctions, { defaultAdminBackendGroupId } from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import UsersDataService from '@/services/UsersDataService'
import { UserGroups } from '@/services/UserGroupDataService'
// import UserGroupDataService from '@/services/UserGroupDataService'
import MembersDataService from '@/services/MembersDataService'
import SysUser from '@/types/SysUser'
import SysUserGroup, { SysUserGroupUserGroupRights, SysUserGroupActiveGroupRights } from '@/types/SysUserGroup'
import SysMember from '@/types/SysMember'
import exportFromJSON from 'export-from-json'

// import SysUserGroupRights from '@/types/SysUserGroupRights'
// import { reactive, watch } from 'vue'

/* export default defineComponent({
  name: 'AdminUsers',
  setup () {
    // component setup
  }
}) */

type brugerDataType = { brugerFornavn: string; brugerEfternavn: string; brugerBrugernavn: string; originalBrugerBrugernavn: string; brugerPassword: string; brugerTelefon: string; brugerEmail: string; brugerLogin: string; brugerUpdate: string; brugerOprettet: string; brugerBrugergruppeId: string; }
type brugerGruppeDataType = { brugerGruppeNavn: string; }
type dataReturnType = { tabValue: string; columnDisplays: any; members: any; brugers: any; usrgroups: any; brugerData: brugerDataType; userSearchInputValue: string; userSearchTerm: string; brugergrupperettighed: SysUserGroupUserGroupRights[]; index: number; error: any; totalPages: number; }

@Options({
  props: {
    msg: String
  }
})
export default class AdminUsers extends Vue {
  msg!: string
  private brugers: SysUser[] = []
  private usrgroups: SysUserGroup[] = []
  private members: SysMember[] = []
  // private usrgroupsrettigheds: SysUserGroupRights[] = []
  private tempBruger = {} as SysUser
  private tempBrugerGruppe = {} as SysUserGroup
  private repeatPassword = ''
  private deleteUserName = ''
  private deleteUserId = 0
  private deleteUserGroupName = ''
  private deleteUserGroupId = 0
  private userGroupFilterValue = 'Vis alt'
  private userGroupFilterOptions: string[] = []
  private extraParameter = ''
  brugerData: brugerDataType = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', brugerLogin: '', brugerUpdate: '', brugerOprettet: '', brugerBrugergruppeId: '' }
  brugerGruppeData: brugerGruppeDataType = { brugerGruppeNavn: '' }
  error: any = null
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Aktive', 'Ikke aktive']
  userGroupNamesValue = 'Vælg her'
  userGroupNamesOptions: string[] = []
  groupRightsCheck: boolean[] = []
  userSearchInputValue = ''
  userSearchTerm = ''
  toggleIconsSortingValue = 0
  private oldSort = ''
  lastCreatedUserId = 0
  lastCreatedUserGroupId = 0
  private submitted = false
  private submitted2 = false
  private editUserModal = false
  private editGroupNestedDrawerR = false
  private editGroupNestedDrawerR2 = false
  private deleteUserGroupWarningModal = false
  private warningModal = false
  private lastEditedBrugerId = 0
  private lastEditedGruppeId = 0
  private currentListSortingOrder = 'created_at:desc'
  private brugergrupperettighed: SysUserGroupUserGroupRights[] = []
  private index = 0
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  private columnDisplays: string[] = ['name', 'userName', 'email', 'telephone', 'userGroup']
  private tabValue = 'Vis og rediger brugere'
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  newPassword = false
  newPasswordString = ''

  readonly name : string = 'AdminUsers'
  $Message: any

  data (): dataReturnType {
    return {
      brugers: this.brugers,
      usrgroups: this.usrgroups,
      brugerData: this.brugerData,
      userSearchInputValue: this.userSearchInputValue,
      userSearchTerm: this.userSearchTerm,
      brugergrupperettighed: this.brugergrupperettighed,
      index: this.index,
      error: this.error,
      totalPages: this.totalPages,
      members: this.members,
      columnDisplays: this.columnDisplays,
      tabValue: 'Vis og rediger brugere'
    }
  }

  @Watch('tabValue')
  onChange () : void {
    console.log('tabValue' + this.tabValue)
  }

  @Watch('columnDisplays')
  onColumnDisplayValueChange () : void {
    console.log(this.columnDisplays)
  }

  @Watch('userGroupFilterValue')
  onUserGroupFilterValueChange () : void {
    if (this.userGroupFilterValue !== 'Vis alt') {
      this.extraParameter = 'usrgroup.brugergruppe_gruppenavn=' + this.userGroupFilterValue
    } else {
      this.extraParameter = ''
    }

    this.retrieveUsers()
  }

  @Watch('deleteUserGroupWarningModal')
  onUserGroupWarningToggleChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteUserGroupName = ''
      this.deleteUserGroupId = 0
    }
  }

  @Watch('warningModal')
  onWarningModalToggleChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteUserId = 0
      this.deleteUserName = ''
    }
  }

  @Watch('filterStatusStringValue')
  onUserStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveUsers()
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1

    this.retrieveUsers()
  }

  public showColumn (column: string) : boolean {
    return this.columnDisplays.includes(column)
  }

  public passwordTest (password: string) : boolean {
    return CommonFunctions.strongPasswordTest(password)
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveUsers()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveUsers()
  }

  // @Watch('toggleIconsSortingValue')
  // onListSortToggleChanged (toggleValue: string, oldToggleValue: string) {
  //   console.log('List sort toggle changed to: ' + toggleValue)
  //   if (toggleValue === 'navn') {
  //     this.currentListSortingOrder = 'bruger_efternavn:asc,bruger_fornavn:asc'
  //   } else if (toggleValue === 'brugernavn') {
  //     this.currentListSortingOrder = 'bruger_brugernavn:asc'
  //   } else if (toggleValue === 'email') {
  //     this.currentListSortingOrder = 'bruger_email:asc'
  //   } else if (toggleValue === 'telefon') {
  //     this.currentListSortingOrder = 'bruger_telefon:asc'
  //   } else if (toggleValue === 'gruppenavn') {
  //     this.currentListSortingOrder = 'bruger_brugergruppe_id.brugergruppe_gruppenavn:asc'
  //   }
  //
  //   this.retrieveUsers()
  // }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'created_at:desc'
      this.oldSort = ''
      this.currentPage = 1
      this.userSearchInputValue = ''
      this.userSearchTerm = ''
    }
    if (sortBy === 'navn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'lastname:asc,firstname:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'lastname:desc,firstname:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'brugernavn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'username:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'username:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'email') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'email:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'email:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'telefon') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'phone:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'phone:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'gruppenavn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'usrgroup.brugergruppe_gruppenavn:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'usrgroup.brugergruppe_gruppenavn:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'login') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'lastActive:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'lastActive:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'created') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'created_at:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'created_at:desc'
        this.oldSort = ''
      }
    }

    this.retrieveUsers()
  }

  @Watch('editUserModal')
  onEditUserModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.brugerData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', brugerLogin: '', brugerUpdate: '', brugerOprettet: '', brugerBrugergruppeId: '' }
      this.userGroupNamesValue = 'Vælg her'
      this.repeatPassword = ''
    }
  }

  get valueFilterStatusString () : string {
    return this.filterStatusStringValue
  }

  public forceRerenderUsersList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public getUserGroupFromGroupId (groupId: number) : string {
    const retVal = this.usrgroups.find(x => x.id === groupId)?.brugergruppe_gruppenavn
    // console.log('retVal = ' + retVal)

    return (retVal === undefined ? 'Ingen' : retVal)
  }

  public getUserGroupIndexFromGroupId (groupId: number) : number {
    const retVal = this.usrgroups.findIndex(x => x.id === groupId)
    // console.log('retVal = ' + retVal)

    return (retVal === undefined ? -1 : retVal)
  }

  public getUserGroupIdFromGroupName (groupName: string) : number | null {
    const retVal = this.usrgroups.find(x => x.brugergruppe_gruppenavn === groupName)?.id
    // console.log('retVal = ' + retVal)

    return (retVal === undefined ? 0 : retVal)
  }

  public getIndexOfGroupRightIdFromActiveUserGroupRights (groupRightId: number, userGroup: SysUserGroup = this.tempBrugerGruppe) : number {
    return userGroup.aktive_grupperettigheder === null ? -1 : userGroup.aktive_grupperettigheder.findIndex(x => x.id === groupRightId)
  }

  public findUser () : void {
    console.log('Search user.')
    this.userSearchTerm = this.userSearchInputValue
    this.retrieveUsers()
  }

  public editUser (brugerId : number) : void {
    let testGruppeId = 0
    console.log('Edit user with Id: ' + brugerId)
    this.editUserModal = true
    this.lastEditedBrugerId = brugerId

    UsersDataService.get(brugerId.toString())
      .then((response) => {
        this.tempBruger = response.data
        console.log(response.data)

        if (this.tempBruger.usrgroup === null || this.tempBruger.usrgroup.id === null) {
          testGruppeId = 0
        } else {
          testGruppeId = Number(this.tempBruger.usrgroup.id)
          console.log('Group Id = ' + testGruppeId)
        }

        this.brugerData = { brugerFornavn: this.tempBruger.firstname, brugerEfternavn: this.tempBruger.lastname, brugerBrugernavn: this.tempBruger.username, originalBrugerBrugernavn: this.tempBruger.username, brugerPassword: '', brugerTelefon: (this.tempBruger.phone === null ? '' : this.tempBruger.phone.toString()), brugerEmail: (this.tempBruger.email === null ? '' : this.tempBruger.email), brugerLogin: new Date(this.tempBruger.lastActive).toISOString().substring(0, 16), brugerUpdate: new Date(this.tempBruger.updated_at).toISOString().substring(0, 16), brugerOprettet: new Date(this.tempBruger.created_at).toISOString().substring(0, 16), brugerBrugergruppeId: testGruppeId.toString() }
        this.userGroupNamesValue = (testGruppeId === 0 ? 'Vælg her' : (this.getUserGroupFromGroupId(testGruppeId) === 'Ingen' ? 'Vælg her' : this.getUserGroupFromGroupId(testGruppeId)))
        // console.log(this.brugerData)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public generateNewPassword () : void {
    this.newPasswordString = CommonFunctions.generatePassword()
    this.newPassword = true
  }

  public cancelNewPassword () : void {
    this.newPasswordString = ''
    this.newPassword = false
  }

  public updatePassword () : void {
    const updatePassword = {
      bruger_password: this.newPasswordString
    }
    UsersDataService.update(this.lastEditedBrugerId.toString(), updatePassword)
      .then((response) => {
        console.log('Password updated: ' + response.statusText)
        this.newPasswordString = ''
        this.newPassword = false
        this.$Message.success({ text: 'Adgangskode er blevet opdateret' })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelUserEdit () : void {
    this.brugerData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', brugerLogin: '', brugerUpdate: '', brugerOprettet: '', brugerBrugergruppeId: '' }
    this.userGroupNamesValue = 'Vælg her'
    this.repeatPassword = ''
  }

  public blockUser (brugerId : number) : void {
    console.log('Block user with Id: ' + brugerId)

    UsersDataService.get(brugerId.toString())
      .then((response) => {
        this.tempBruger = response.data
        console.log(response.data)

        const updateUserData = {
          blocked: !this.tempBruger.blocked
        }

        console.log('New status of user = ' + updateUserData.blocked)
        // location.reload()

        UsersDataService.update(brugerId.toString(), updateUserData)
          .then((response) => {
            // this.lastUpdatedUserId = response.data.id
            console.log(response.data)

            const brugersIndexToUpdateStatus = this.brugers.findIndex(x => x.id === brugerId)
            this.brugers[brugersIndexToUpdateStatus].blocked = updateUserData.blocked
            // this.forceRerenderUsersList()
            // this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public blockUserGroup (usrGroupId : number) : void {
    console.log('Change user group status of Id: ' + usrGroupId)

    UserGroups.UserGroupDataService.get(usrGroupId.toString())
      .then((response) => {
        this.tempBrugerGruppe = response.data
        console.log(response.data)

        const updateUserGroupData = {
          brugergruppe_status: !this.tempBrugerGruppe.brugergruppe_status
        }

        console.log('New status of user group = ' + updateUserGroupData.brugergruppe_status)

        UserGroups.UserGroupDataService.update(usrGroupId.toString(), updateUserGroupData)
          .then((response) => {
            // this.lastUpdatedUserGroupId = response.data.id
            console.log(response.data)
            this.usrgroups[this.getUserGroupIndexFromGroupId(response.data.id)].brugergruppe_status = updateUserGroupData.brugergruppe_status
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteUserWarning (brugerId: number, userName: string) : void {
    this.deleteUserName = userName
    this.deleteUserId = brugerId
    this.warningModal = true
  }

  public deleteUser (brugerId : number) : void {
    console.log('Delete user with the Id: ' + brugerId)
    MembersDataService.getAll('', null, `user_id.id=${brugerId}`)
      .then((response) => {
        this.members = response.data
        if (this.members.length === 0) {
          UsersDataService.delete(brugerId.toString())
            .then((response) => {
              console.log(response.data)
              this.retrieveUsers()
              this.warningModal = false
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
              this.retrieveUsers()
              this.warningModal = false
            })
        } else {
          this.warningModal = false
          alert('Når du forsøger at slette en bruger, der også er et medlem, så skal det foregå via Medlemmer')
          this.retrieveUsers()
        }
      })
      .catch((err) => {
        this.error = err
        this.retrieveUsers()
      })
  }

  public deleteUserGroupWarning (groupName: string, groupId: number) : void {
    this.deleteUserGroupName = groupName
    this.deleteUserGroupId = groupId
    this.deleteUserGroupWarningModal = true
  }

  public deleteUserGroup (usrGroupId : number) : void {
    console.log('Deleting user group with the Id: ' + usrGroupId)

    // Only allow deleting non-default user groups
    if (usrGroupId >= 100) {
      UserGroups.UserGroupDataService.delete(usrGroupId.toString())
        .then((response) => {
          console.log(response.data)
          this.retrieveUserGroups()
          // this.retrieveUsers()
          this.deleteUserGroupWarningModal = false
        })
        .catch((err) => {
          this.error = err
          console.log(err)
          this.deleteUserGroupWarningModal = false
        })
    }
  }

  public createUser () : void {
    const tempBrugerTelefon : string = this.brugerData.brugerTelefon.toString().trim()
    let userGroupId = 1

    if (this.brugerData.brugerFornavn.length > 1 && this.brugerData.brugerEfternavn.length > 1 &&
      this.brugerData.brugerBrugernavn.length > 1 && this.passwordTest(this.brugerData.brugerPassword) &&
      tempBrugerTelefon.length > 7 && !isNaN(Number(tempBrugerTelefon)) &&
      this.brugerData.brugerEmail.length > 1 && BaseFunctions.isEmail(this.brugerData.brugerEmail) &&
      this.userGroupNamesValue !== 'Vælg her') {
      if (this.userGroupNamesValue !== 'Vælg her') {
        userGroupId = Number(this.getUserGroupIdFromGroupName(this.userGroupNamesValue))
      }

      // Perform a check whether the username is unique or not. If it is not unique then bail out with an appropriate message to the end user without making any changes.
      UsersDataService.getAll('', null, `username=${this.brugerData.brugerBrugernavn}`)
        .then((response) => {
          const testUserName = response.data as SysUser[]

          if (testUserName.length > 0) {
            this.$Message.danger({ text: 'Der eksisterer i forvejen en bruger med dette brugernavn, vælg et andet' })
          } else {
            const createUserData = {
              firstname: this.brugerData.brugerFornavn,
              lastname: this.brugerData.brugerEfternavn,
              username: this.brugerData.brugerBrugernavn,
              password: this.brugerData.brugerPassword,
              phone: Number(tempBrugerTelefon),
              email: this.brugerData.brugerEmail,
              usrgroup: (userGroupId > 0 ? userGroupId : 1),
              provider: 'local',
              role: '3',
              confirmed: true
            }

            // const createUserData = {
            //   bruger_fornavn: this.brugerData.brugerFornavn,
            //   bruger_efternavn: this.brugerData.brugerEfternavn,
            //   bruger_brugernavn: this.brugerData.brugerBrugernavn,
            //   bruger_password: this.brugerData.brugerPassword,
            //   bruger_telefon: tempBrugerTelefon,
            //   bruger_email: this.brugerData.brugerEmail,
            //   bruger_brugergruppe_id: (userGroupId > 0 ? userGroupId : 1)
            // }

            console.log('Attempt to create new user with Id: ' + (1 + this.lastCreatedUserId))

            UsersDataService.create(createUserData)
              .then((response) => {
                this.lastCreatedUserId = response.data.id
                console.log(response.data)
                this.submitted = true
              })
              .catch((err) => {
                this.error = err
                console.log(err)
              })
              .then(() => {
                this.$Message.success({ text: 'Brugeren er blevet oprettet' })
                this.newCreateUser()
                this.tabValue = 'Vis og rediger brugere'
                CommonFunctions.scrollToTop()
              })
          }
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public newCreateUser () : void {
    this.submitted = false
    this.brugerData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', brugerLogin: '', brugerUpdate: '', brugerOprettet: '', brugerBrugergruppeId: '' }
    this.userGroupNamesValue = 'Vælg her'
    this.repeatPassword = ''
    this.retrieveUsers()
  }

  public createUserGroup () : void {
    console.log('Create a new user group.')

    if (this.brugerGruppeData.brugerGruppeNavn.length > 1) {
      const createUserGroupData = {
        brugergruppe_gruppenavn: this.brugerGruppeData.brugerGruppeNavn,
        brugergruppe_status: true
      }

      console.log('Attempt to create new user group')

      UserGroups.UserGroupDataService.create(createUserGroupData)
        .then((response) => {
          this.lastCreatedUserGroupId = response.data.id
          console.log(response.data)
          this.submitted2 = true
          this.retrieveUserGroups()
          this.retrieveUsers()
        })
        .catch((err) => {
          this.error = err
          console.log(err)
        })
        .then(() => {
          this.newCreateUserGroup()
          this.$Message.success({ text: 'Brugergruppen er oprettet' })
          this.tabValue = 'Vis og rediger brugergrupper'
          CommonFunctions.scrollToTop()
        })
    }
  }

  public newCreateUserGroup () : void {
    this.submitted2 = false
    this.brugerGruppeData = { brugerGruppeNavn: '' }
    this.retrieveUserGroups()
    this.retrieveUsers()
  }

  public async updateUser () : Promise<boolean> {
    console.log('Update information of user with Id: ' + this.lastEditedBrugerId)
    let userGroupId: null | number = null
    const tempBrugerTelefon : string = this.brugerData.brugerTelefon.toString()

    // Perform checks if there is a phone number present
    if (this.brugerData.brugerTelefon.length > 0) {
      if (!Number.isInteger(Number(tempBrugerTelefon)) || !(tempBrugerTelefon.trim().length > 7)) {
        this.$Message.warning({ text: 'Advarsel telefonnummeret er ikke korrekt' })

        return false
      }
    }

    // console.log('Gruppe Id = ' + this.brugerData.brugerBrugergruppeId)

    // Perform some checks that there is relevant data in all input fields, and bail out with an appropriate error message to the end user without further processing, if any of these checks fails.
    if (!(this.brugerData.brugerFornavn.trim().length > 1) ||
      !(this.brugerData.brugerEfternavn.trim().length > 1) ||
      !(this.brugerData.brugerBrugernavn.trim().length > 1) ||
      !(this.brugerData.brugerEmail.trim().length > 1) ||
      !BaseFunctions.isEmail(this.brugerData.brugerEmail)) {
      this.$Message.danger({ text: 'Fejl: Et felt eller flere felter er ikke udfyldt korrekt' })

      return false
    }

    if (this.userGroupNamesValue !== 'Vælg her') {
      // Obtain the matching usergroup Id from name value of the selected usergroup.
      userGroupId = this.getUserGroupIdFromGroupName(this.userGroupNamesValue)
    } else {
      userGroupId = defaultAdminBackendGroupId // Sets the usergroup Id to the default group Id for the administration backend if nothing else is indicated.
    }

    let tempUsers: SysUser[] = []

    if (this.brugerData.brugerBrugernavn !== this.brugerData.originalBrugerBrugernavn) {
      try {
        tempUsers = (await UsersDataService.getAll('', null, `username=${this.brugerData.brugerBrugernavn}`)).data
        console.log(tempUsers)
      } catch (err) {
        this.error = err
      }
    }

    if (tempUsers.length > 0) {
      this.$Message.danger({ text: 'Fejl: Brugernavnet er allerede i brug, - vælg et andet' })

      return false
    }

    const updateUserData = {
      firstname: this.brugerData.brugerFornavn,
      lastname: this.brugerData.brugerEfternavn,
      username: this.brugerData.brugerBrugernavn,
      phone: Number(tempBrugerTelefon),
      email: this.brugerData.brugerEmail,
      usrgroup: userGroupId
    }

    // const updateUserData = {
    //   bruger_fornavn: this.brugerData.brugerFornavn,
    //   bruger_efternavn: this.brugerData.brugerEfternavn,
    //   bruger_brugernavn: this.brugerData.brugerBrugernavn,
    //   bruger_telefon: tempBrugerTelefon,
    //   bruger_email: this.brugerData.brugerEmail,
    //   bruger_brugergruppe_id: userGroupId
    // }

    UsersDataService.update(this.lastEditedBrugerId.toString(), updateUserData)
      .then((response) => {
        this.$Message.success({ text: 'Brugeren er blevet opdateret' })
        this.editUserModal = false
        this.newUpdateUser()
        this.retrieveUsers()
      })
      .catch((err) => {
        this.error = err
      })
    // if (this.brugerData.brugerBrugernavn !== this.brugerData.originalBrugerBrugernavn) {
    //   UsersDataService.getAll('', null, `bruger_brugernavn=${this.brugerData.brugerBrugernavn}`)
    //     .then((response) => {
    //       if (response.data.length > 0) {
    //         this.$Message.danger({ text: 'Fejl: Brugernavnet skal være unikt' })
    //         return false
    //       } else {
    //         const updateUserData = {
    //           bruger_fornavn: this.brugerData.brugerFornavn,
    //           bruger_efternavn: this.brugerData.brugerEfternavn,
    //           bruger_brugernavn: this.brugerData.brugerBrugernavn,
    //           bruger_telefon: tempBrugerTelefon,
    //           bruger_email: this.brugerData.brugerEmail,
    //           bruger_brugergruppe_id: userGroupId
    //         }
    //         const updateUserDataWithPW = {
    //           bruger_fornavn: this.brugerData.brugerFornavn,
    //           bruger_efternavn: this.brugerData.brugerEfternavn,
    //           bruger_brugernavn: this.brugerData.brugerBrugernavn,
    //           bruger_password: this.brugerData.brugerPassword,
    //           bruger_telefon: tempBrugerTelefon,
    //           bruger_email: this.brugerData.brugerEmail,
    //           bruger_brugergruppe_id: userGroupId
    //         }

    //         UsersDataService.update(this.lastEditedBrugerId.toString(), (this.brugerData.brugerPassword === '' ? updateUserData : updateUserDataWithPW))
    //           .then((response) => {
    //             console.log(response.data)
    //             this.$Message.success({ text: 'Brugeren er blevet opdateret' })
    //             this.editUserModal = false
    //             this.newUpdateUser()
    //             this.retrieveUsers()
    //             return true
    //           })
    //           .catch((err) => {
    //             this.error = err
    //           })
    //       }
    //     })
    //     .catch((err) => {
    //       this.error = err
    //     })
    // } else {
    //   const updateUserData = {
    //     bruger_fornavn: this.brugerData.brugerFornavn,
    //     bruger_efternavn: this.brugerData.brugerEfternavn,
    //     bruger_brugernavn: this.brugerData.brugerBrugernavn,
    //     bruger_telefon: tempBrugerTelefon,
    //     bruger_email: this.brugerData.brugerEmail,
    //     bruger_brugergruppe_id: userGroupId
    //   }
    //   const updateUserDataWithPW = {
    //     bruger_fornavn: this.brugerData.brugerFornavn,
    //     bruger_efternavn: this.brugerData.brugerEfternavn,
    //     bruger_brugernavn: this.brugerData.brugerBrugernavn,
    //     bruger_password: this.brugerData.brugerPassword,
    //     bruger_telefon: tempBrugerTelefon,
    //     bruger_email: this.brugerData.brugerEmail,
    //     bruger_brugergruppe_id: userGroupId
    //   }

    //   UsersDataService.update(this.lastEditedBrugerId.toString(), (this.brugerData.brugerPassword === '' ? updateUserData : updateUserDataWithPW))
    //     .then((response) => {
    //       console.log(response.data)
    //       this.$Message.success({ text: 'Brugeren er blevet opdateret' })
    //       this.editUserModal = false
    //       this.newUpdateUser()
    //       this.retrieveUsers()
    //       return true
    //     })
    //     .catch((err) => {
    //       this.error = err
    //     })
    // }

    // // this.brugerData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', brugerLogin: '', brugerUpdate: '', brugerOprettet: '', brugerBrugergruppeId: '' }
    // // this.userGroupNamesValue = 'Vælg her'

    return true
  }

  public newUpdateUser () : void {
    this.brugerData = { brugerFornavn: '', brugerEfternavn: '', brugerBrugernavn: '', originalBrugerBrugernavn: '', brugerPassword: '', brugerTelefon: '', brugerEmail: '', brugerLogin: '', brugerUpdate: '', brugerOprettet: '', brugerBrugergruppeId: '' }
    this.userGroupNamesValue = 'Vælg her'
    this.repeatPassword = ''
  }

  updateUserGroupRights (inputVal: number) : void {
    console.log('Updating user groups rights data in the API.')
    const activeGroupRightsJSON: SysUserGroupActiveGroupRights[] = []

    // console.log('groupRightsCheck = ' + this.groupRightsCheck + ' ; last index = ' + this.groupRightsCheck.lastIndexOf(true))

    // Perform som sanity checks and also prevent attempts to change the rights of administrator users.
    if (this.groupRightsCheck.indexOf(true) > 0 && this.groupRightsCheck.lastIndexOf(true) && this.lastEditedGruppeId !== 2) {
      for (let userGroupRightElement = this.groupRightsCheck.indexOf(true); userGroupRightElement <= this.groupRightsCheck.lastIndexOf(true); userGroupRightElement++) {
        // console.log('groupRightsCheck for ' + userGroupRightElement + ' = ' + this.groupRightsCheck[userGroupRightElement])
        if (this.groupRightsCheck[userGroupRightElement] === true) {
          activeGroupRightsJSON.push({ id: userGroupRightElement })
          // console.log('Active group rights Id pushed = ' + userGroupRightElement)
        }
      }
    }

    const updateUserGroupData = {
      aktive_grupperettigheder: activeGroupRightsJSON
    }

    console.log('New active group rights of the user group = ' + JSON.stringify(updateUserGroupData.aktive_grupperettigheder))

    UserGroups.UserGroupDataService.update(this.lastEditedGruppeId.toString(), updateUserGroupData)
      .then((response) => {
        // this.lastUpdatedUserGroupId = response.data.id
        // console.log(response.data)

        if (inputVal) {
          this.editGroupNestedDrawerR2 = false
        }
        // this.$forceUpdate()
      })
      .catch((err) => {
        this.error = err
        console.log(err)
      })
  }

  retrieveUserGroupRights (usrGroupId : number) : void {
    this.lastEditedGruppeId = usrGroupId
    console.log('Fetching user groups rights data from API.')

    UserGroups.UserGroupDataService.get(usrGroupId.toString())
      .then((response) => {
        this.tempBrugerGruppe = response.data
        console.log(response.data)

        // Initialize checkbox values for the allowed user group rights entries for the user group.
        if (this.tempBrugerGruppe.usrgrouprights !== null) {
          // Initialize array of checkbox values for the group rights with an array size of the maximum value of the id of the groups rights allowed for the user group
          const maxIdValueOfGroupRights = Math.max(...this.tempBrugerGruppe.usrgrouprights.map(o => Number(o.id)), 1)
          this.groupRightsCheck = new Array(maxIdValueOfGroupRights).fill(false)

          for (let userGroupRightElement = 0, elementID = -1; userGroupRightElement < this.tempBrugerGruppe.usrgrouprights.length; userGroupRightElement++) {
            elementID = Number(this.tempBrugerGruppe.usrgrouprights[userGroupRightElement].id === null ? -1 : this.tempBrugerGruppe.usrgrouprights[userGroupRightElement].id)
            if (elementID >= 0) {
              this.groupRightsCheck[elementID] = (this.getIndexOfGroupRightIdFromActiveUserGroupRights(elementID) >= 0)
            } else {
              this.groupRightsCheck[elementID] = false
            }
          }
        }
        this.editGroupNestedDrawerR2 = true
        // this.forceRerenderUsersList()
        // this.$forceUpdate()
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
        this.editGroupNestedDrawerR2 = false
        this.editGroupNestedDrawerR = false
      })
  }

  public retrieveUserGroups () : void {
    console.log('Fetching user groups data from API.')

    UserGroups.UserGroupDataService.getAll(this.currentListSortingOrder)
      .then((response) => {
        this.usrgroups = response.data
        console.log(response.data)

        this.userGroupFilterOptions = []
        this.userGroupFilterOptions.push('Vis alt')
        this.userGroupNamesOptions = []
        this.userGroupNamesOptions.push('Vælg her')
        for (const usrgroup of this.usrgroups) {
          this.userGroupNamesOptions.push(usrgroup.brugergruppe_gruppenavn)
          this.userGroupFilterOptions.push(usrgroup.brugergruppe_gruppenavn)
        }
        // console.log('[retrieveUserGroups] Usergroup names = ' + this.userGroupNamesOptions)

        this.forceRerenderUsersList()
        this.$forceUpdate()
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public retrieveUsers () : void {
    console.log('Counting Users')
    let countFilter = ''
    if (this.filterStatusStringValue === 'Vis alt') {
      countFilter = this.extraParameter
    } else if (this.filterStatusStringValue === 'Aktive') {
      countFilter = 'blocked=false&' + this.extraParameter
    } else {
      countFilter = 'blocked=true&' + this.extraParameter
    }
    UsersDataService.getCount(countFilter)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries: ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })
    console.log('Fetching users data from API.')
    if (this.userSearchTerm.length > 0) {
      UsersDataService.findBySearchTerm((this.filterStatusStringValue === 'Aktive'), (this.filterStatusStringValue === 'Vis alt'), this.userSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
        .then((response) => {
          this.brugers = response.data
          // console.log(response.data)
          this.forceRerenderUsersList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        UsersDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
          .then((response) => {
            this.brugers = response.data
            // console.log(response.data)
            this.forceRerenderUsersList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        UsersDataService.findByUserStatus((this.filterStatusStringValue === 'Aktive'), this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
          .then((response) => {
            this.brugers = response.data
            // console.log(response.data)
            this.forceRerenderUsersList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  public exportUser () : void {
    console.log('Export user to txt')
    const data = {
      Brugernavn: this.brugerData.brugerBrugernavn,
      Navn: this.brugerData.brugerFornavn + ' ' + this.brugerData.brugerEfternavn,
      Telefon: this.brugerData.brugerTelefon,
      Email: this.brugerData.brugerEmail,
      Brugergruppe: this.getUserGroupFromGroupId(Number(this.brugerData.brugerBrugergruppeId)),
      Oprettet: this.brugerData.brugerOprettet,
      SidsteLogin: (this.brugerData.brugerLogin === '1970-01-01T00:00' ? 'Aldrig' : this.brugerData.brugerLogin),
      SidsteOpdateret: this.brugerData.brugerUpdate
    }
    const fileName = 'ekporter_' + this.brugerData.brugerFornavn + '_' + this.brugerData.brugerEfternavn
    const exportType = exportFromJSON.types.txt

    exportFromJSON({ data, fileName, exportType })
  }

  public checkEmail (email: string) : boolean {
    return BaseFunctions.isEmail(email)
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveUserGroups()
        this.retrieveUsers()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
